import React, {useContext, useEffect, useRef, useState} from "react"
import {DIRECT_TYPE, PlayerContext, PODCAST_TYPE} from "./context/player-context"
import {DirectInformationContext} from "./context/direct-information-context";

export const secondToTime = (value) => {
    let totalSecond = parseInt(value, 10) // don't forget the second param
    let hours = Math.floor(totalSecond / 3600)
    let minutes = Math.floor((totalSecond - (hours * 3600)) / 60)
    let seconds = totalSecond - (hours * 3600) - (minutes * 60)

    let displayHours = hours > 0
    if (hours < 10) {
        hours = "0" + hours
    }
    if (minutes < 10) {
        minutes = "0" + minutes
    }
    if (seconds < 10) {
        seconds = "0" + seconds
    }
    return displayHours ? hours + ":" + minutes + ":" + seconds : minutes + ":" + seconds
}

const Player = () => {
    const directInformationContext = useContext(DirectInformationContext)
    const { artist, title } = directInformationContext
    const playerContext = useContext(PlayerContext)
    const {play, setPlay, mute, setMute, audio, enabled, content, load} = playerContext
    const [duration, setDuration] = useState(audio ? audio.duration : 0)
    const [currentTime, setCurrentTime] = useState(audio ? audio.currentTime : 0)
    const progress = useRef(null)
    const [up, setUp] = useState(false)
    const [down, setDown] = useState(false)
    const [move, setMove] = useState(false)

    const handlePausePlay = () => {
        if (play) {
            setPlay(false)
            audio.pause()
        } else {
            if (content.type === DIRECT_TYPE) {
                audio.src = process.env.STREAMED_RADIO_URL
            }

            setPlay(true)
            audio.play()
        }
    }

    const handleMute = () => {
        setMute(!mute)
        audio.muted = !mute
    }

    const handleMouseDown = (event) => {
        seek(event)
        audio.pause()
        setUp(false)
        setDown(true)
    }

    const handleMouseMove = (event) => {
        if (!up && down) {
            setMove(true)
            seek(event)
            audio.pause()
        }
    }
    const handleMouseUp = () => {
        setMove(false)
        setUp(true)
        setDown(false)
        if (play) {
            audio.play()
        }
    }
    const handleMouseOut = () => {
        if (move) {
            setMove(false)
            setUp(true)
            setDown(false)
            if (play) {
                audio.play()
            }
        }
    }

    const seek = (event) => {
        let percent = event.nativeEvent.offsetX / progress.current.offsetWidth
        let newValue = percent * audio.duration
        audio.currentTime = newValue
        setCurrentTime(newValue)
    }

    useEffect(() => {
        audio.onloadedmetadata = (() => {
            if (content.type === PODCAST_TYPE) {
                setDuration(audio.duration)
            }
        })

        audio.ontimeupdate = (() => {
            if (content.type === PODCAST_TYPE) {
                setCurrentTime(audio.currentTime)
            }
        })
    }, [audio.src, content])

    useEffect(() => {
        if (currentTime > 0 && duration === currentTime) {
            setPlay(false)
        }
    }, [duration, currentTime])

    return (
        <nav id="player" className={`navbar is-fixed-bottom is-dark ${enabled ? "" : "is-hidden"}`}>
            <div className="navbar-start">
                <div className="navbar-item">
                    <button
                        onClick={handlePausePlay}
                        className={load
                            ? 'is-loading-button'
                            : play
                                ? content.type === DIRECT_TYPE
                                    ? "is-stop-o-button"
                                    : "is-pause-o-button"
                                : "is-play-o-button"
                        }>
                        {play ? "stop" : "play"}
                    </button>
                </div>
                {content.type !== PODCAST_TYPE && (
                    <div className="navbar-item direct-infos">
                        <div className="description">{artist} - {title}</div>
                    </div>
                )}
            </div>
            {content.type === PODCAST_TYPE && (
                <div className="navbar-middle">
                    <div className="progress-info">
                        <div className="current-time">
                            {secondToTime(currentTime)}
                        </div>
                        <div className="duration">
                            {secondToTime(duration)}
                        </div>
                    </div>
                    <progress
                        onMouseOut={handleMouseOut}
                        onMouseDown={handleMouseDown} onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        ref={progress}
                        className="progress is-danger is-small" value={currentTime} max={duration}
                    >{currentTime}%
                    </progress>
                    <div className="progress-title">
                        {content.description}
                    </div>
                </div>
            )}
            <div className="navbar-end">
                <div className="navbar-item">
                    <button
                        onClick={handleMute}
                        className={mute ? "is-muted-o-button" : "is-unmute-o-button"}
                    >
                        {mute ? "Unmute" : "Mute"}
                    </button>
                </div>
            </div>
        </nav>
    )
}

export default Player