import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import MenuLink from "./menu-link"

export const FooterMenu = () => {
  const data = useStaticQuery(graphql`
  {
    footerMenu: allMenuLinkContentMenuLinkContent(
      filter: {enabled: {eq: true}, 
      menu_name: {eq: "footer"}}, 
      sort: {fields: [drupal_parent_menu_item, weight], order: DESC}
    ) {
      nodes {
        title
        link {
          uri
        }
      }
    }
  }
 `)

  return (
    <ul id="menu-footer" className="menu">
      {data.footerMenu.nodes.map((menu, index) => (
        <li className="menu-item" key={index}>
          <MenuLink internalId={menu.link.uri} className="menu-link">
            {menu.title}
          </MenuLink>
        </li>
      ))}
    </ul>
  )
}
