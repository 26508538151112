import React from "react"
import { Link } from "gatsby"
import Logo from "../images/logo.svg"

export const Coordinates = ({ isInline }) => (
  <div className={`coordinates${isInline ? ' is-inline' : ''}`}>
    {isInline ? (
      <div className="columns">
        <div className="column">
          <div style={{width: '125px', height: '125px'}}>
            <Link to="/">
              <Logo />
            </Link>
          </div>
        </div>
        <div className="column content">
          <h3 className="title is-4">Fugi FM</h3>
          <small>
            32 Avenue Jules Lartigue <br/>
            08600 Givet <br/>
            <a href="tel:+33324418777">03 24 41 87 77</a>
          </small>
        </div>
      </div>
    ) : (
      <>
        <div className="logo" style={{width: '125px', height: '125px'}}>
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <h3 className="title is-4">Fugi FM</h3>
        <small>
          32 Avenue Jules Lartigue <br/>
          08600 Givet <br/>
          <a href="tel:+33324418777">03 24 41 87 77</a>
        </small>
      </>
    )}
  </div>
)