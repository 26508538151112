import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, image, imageHeight, imageWidth }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            imageWidth
            imageHeight
          }
        }
      }
    `
  )

  const limit = 300

  let metaDescription = description || site.siteMetadata.description
  metaDescription = metaDescription.replace(/(<([^>]+)>)/ig,"")
  metaDescription = metaDescription.substr(0, limit) + "...";

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image || site.siteMetadata.image,
        },
        {
          property: `og:image:width`,
          content: imageWidth || site.siteMetadata.imageWidth,
        },
        {
          property: `og:image:height`,
          content: imageHeight || site.siteMetadata.imageHeight,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `google-site-verification`,
          content: `c2r61uPUWWEdK-E999UQKIVVzzwanK6N0d6Q8pog1uE`
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
