import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import MenuLink from "./menu-link";

export const SocialMenu = () => {
  const data = useStaticQuery(graphql`
    {
      socialMenu: allMenuLinkContentMenuLinkContent(
        filter: {enabled: {eq: true}, menu_name: {eq: "social"}}, 
        sort: {fields: [drupal_parent_menu_item, weight], 
        order: DESC}
      ) {
        nodes {
          title 
          link {
            uri
          }
        }
      }
    }
 `)

  return (
    <ul className="menu menu-social">
      {data.socialMenu.nodes.map((menu, index) => (
        <li className="menu-item" key={index}>
          <MenuLink internalId={menu.link.uri} className="menu-link" title={menu.title}>
            {menu.title}
          </MenuLink>
        </li>
      ))}
    </ul>
  )
}