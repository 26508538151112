import { Link } from "gatsby"
import React from "react"
import EntityLink from "./entity-link.js";

const MenuLink = ({ children, internalId, ...props}) => {
  let pathInfo = internalId.split(':');

  const handleClick = () => {
    document.documentElement.classList.remove('menu-open')
  }

  return (
    <>
      {pathInfo[0] === 'entity' ? (
        // Entity link
        <EntityLink internalId={internalId} onClick={handleClick} activeClassName="is-active" {...props}>
          {children}
        </EntityLink>
      ) : pathInfo[0] === 'internal' ? (
        // Internal link (content list or #)
        <Link to={pathInfo[1]} onClick={handleClick} activeClassName="is-active" {...props}>
          {children}
        </Link>
      ) : (
        // External link
        <Link to={internalId} onClick={handleClick} activeClassName="is-active" target="_blank" {...props}>
          {children}
        </Link>
      )}
    </>
  )
}

export default MenuLink
