import React, {useContext, useEffect, useState} from "react"
import { DirectInformationContext } from "./context/direct-information-context"
import { DIRECT_TYPE, PlayerContext } from "./context/player-context"

const Direct = () => {
  const directInformationContext = useContext(DirectInformationContext)
  const { artist, title } = directInformationContext
  const playerContext = useContext(PlayerContext)
  const { play, audio, content, load, setPlay, setContent, setEnabled } = playerContext
  const [directPlay, setDirectPlay] = useState(false)

  const handleToggle = () => {
    setEnabled(true)

    if (!load) {
      if (content.type === DIRECT_TYPE) {
        if (play) {
          setPlay(false)
          audio.pause()
        } else {
          setPlay(true)
          audio.src = process.env.STREAMED_RADIO_URL
          audio.play()
        }
      }
      else {
        setContent({type: DIRECT_TYPE, description: null})
        setPlay(true)
        audio.src = process.env.STREAMED_RADIO_URL
        audio.play()
      }
    }
  }

  useEffect(() => {
    if (play && content.type === DIRECT_TYPE) {
      setDirectPlay(true)
    } else {
      setDirectPlay(false)
    }
  }, [play])
  
  return (
    <a onClick={handleToggle} className={`navbar-item play-button${load ? ' is-loading' : ''}`}>
      <span className="button-content">
        <span className="button-label">{directPlay ? "Stopper le direct" : "Écouter le direct"}</span>
        <span className="button-label-mobile">Le direct</span>
        {(artist && title) && (
          <span className="music-info">{artist} - {title}</span>
        )}
      </span>
    </a>
  )
}

export default Direct
