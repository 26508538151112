import React from "react"
import Menu from "./menu"

const Header = () => {
  return (
    <header>
      <Menu/>
    </header>
  )
}

export default Header