import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const FrequenceMenu = () => {
  const data = useStaticQuery(graphql`
  {
    frequenceMenu: allMenuLinkContentMenuLinkContent(filter: {enabled: {eq: true}, menu_name: {eq: "frequences"}}, sort: {fields: [drupal_parent_menu_item, weight], order: DESC}) {
      nodes {
        title
        link {
          uri
        }
      }
    }
  }
 `)

  return (
    <ul id="menu-frequency" className="menu columns is-multiline">
      {data.frequenceMenu.nodes.map((menu, index) => (
        <li className="menu-item column is-6" key={index}>
          <span className="menu-link">{menu.title}</span>
        </li>
      ))}
    </ul>
  )
}