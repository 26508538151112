import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import MenuLink from "./menu-link"
import MenuDropdown from "./menu-dropdown";
import Direct from "./direct"
import Logo from "../images/logo.svg"
import {SocialMenu} from "./social-menu";

const Menu = () => {
  const data = useStaticQuery(graphql`
    query {
      allMenuLinkContentMenuLinkContent(
        filter: {enabled: {eq: true}, menu_name: {eq: "main"}}, 
        sort: {fields: [drupal_parent_menu_item, weight], order: DESC}
      ) {
        edges {
          node {
            title
            drupal_parent_menu_item
            drupal_id
            link {
              uri
            }
          }
        }
      }
    }
  `)

  const [dropdownState, setDropdownState] = useState(false);

  let menuWithChildren = new Map()

  data.allMenuLinkContentMenuLinkContent.edges.forEach(({ node }) => {
    if (node.drupal_parent_menu_item) {
      let parentId = node.drupal_parent_menu_item.split(":")[1]
      menuWithChildren.get(parentId).children.push(node)
    } else {
      menuWithChildren.set(node.drupal_id, { parent: node, children: [] })
    }
  })

  let computeMenu = []

  menuWithChildren.forEach((item, index) => {
    computeMenu.push(
      item.children.length > 0
          ? <MenuDropdown parent={item.parent} childrens={item.children} key={index} />
          : <MenuLink internalId={item.parent.link.uri} className="navbar-item" key={index}>
            {item.parent.title}
          </MenuLink>
      ,
    )
  })

  function handleMenuDropdown(state) {
    setDropdownState(state)

    if (state) {
      document.documentElement.classList.add('menu-open')
    } else {
      document.documentElement.classList.remove('menu-open')
    }
  }

  return (
    <nav id="main-nav" className="navbar is-primary">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          <span>
            <Logo />
          </span>
        </Link>
        <div
          className={`navbar-burger${dropdownState ? ' is-active' : ''}`}
          data-target="navbarMain"
          onClick={() => { handleMenuDropdown(!dropdownState) }}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div id="navbarMain" className={`navbar-menu${dropdownState ? ' is-active' : ''}`}>
        <div
          data-target="navbarMain"
          className={`navbar-burger${dropdownState ? ' is-active' : ''}`}
          onClick={() => { handleMenuDropdown(!dropdownState) }}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div className="navbar-start">
          {computeMenu}
        </div>

        <div className="navbar-social">
          <SocialMenu/>
        </div>
      </div>


      <div className="navbar-direct">
        <Direct />
      </div>
    </nav>
  )
}

export default Menu
