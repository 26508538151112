import React, {useState, useEffect} from "react"
import MenuLink from "./menu-link";

const MenuDropdown = ({ parent, childrens, ...props}) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
      childrens.map((child, index) => {
          let pathInfo = child.link.uri.split(':')

          if (pathInfo[1] === document.location.pathname) {
              setActive(true)
          }
      })
  }, [active]);

  return (
      <div className={`navbar-item has-dropdown is-hoverable${active ? ' has-active-item' : ''}`} onClick={() => {setActive(true)}} {...props}>
          <MenuLink internalId={parent.link.uri} className="navbar-link">
              {parent.title}
          </MenuLink>
          {childrens.length > 0 && (
              <div className="navbar-dropdown">
                  {childrens.map((child, index) => (
                      <MenuLink key={index} internalId={child.link.uri} className="navbar-item">
                         {child.title}
                      </MenuLink>
                  ))}
              </div>
          )}
      </div>
  )
}

export default MenuDropdown
