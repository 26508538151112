import React from "react"
import PropTypes from "prop-types"
import "../styles/index.scss";
import "../../static/tarteaucitronjs/css/tarteaucitron.css"
import Header from "./header"
import Player from "./player"
import {Coordinates} from "./coordinates";
import {FrequenceMenu} from "./frequence-menu";
import {FooterMenu} from "./footer-menu";
import {SocialMenu} from "./social-menu";

const Layout = ({ children }) => {
  return (
    <>
      <div id="main-wrapper">
        <div id="main-content">
          <Header/>
          <main>
            {children}
            <Player/>
          </main>
        </div>
        <div id="memento" className="section has-background-primary">
          <div className="container">
            <div className="columns">
              <div className="column is-12">
                <SocialMenu/>
              </div>
            </div>
          </div>
        </div>
        <footer id="footer" className="section">
          <div className="pre-footer">
            <div className="container">
              <div className="columns is-multiline">
                <div className="column is-3-widescreen is-6">
                  <Coordinates isInline={true}/>
                </div>
                <div className="column is-6">
                  <FrequenceMenu/>
                </div>
                <div className="column is-3-widescreen is-12">
                  <FooterMenu/>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright">
            <div className="container">
              <div className="columns is-multiline">
                <div className="column is-12 has-text-centered">
                  <small>
                    © Copyright {new Date().getFullYear()} Fugi FM - Une création <a href="https://www.isics.fr" target="_blank" rel="noopener noreferrer">Isics</a>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
