import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"

const EntityLink = ({ children, internalId, ...props}) => {
  const menuDataLink = useStaticQuery(graphql`
    query {
      allNodeEmission {
        edges {
          node {
            internalId: drupal_internal__nid
            path {
              alias
            }
          }
        }
      }
      allNodeArticle {
        edges {
          node {
            internalId: drupal_internal__nid
            path {
              alias
            }
          }
        }
      }
      allNodeJeuConcours {
        edges {
          node {
            internalId: drupal_internal__nid
            path {
              alias
            }
          }
        }
      }
      allNodePage {
        edges {
          node {
            internalId: drupal_internal__nid
            path {
              alias
            }
          }
        }
      }
      allNodePodcast {
        edges {
          node {
            internalId: drupal_internal__nid
            path {
              alias
            }
          }
        }
      }
    }
  `)

  let searchValue = internalId.split("/")[1]
  let id = parseInt(searchValue, 10)
  let path = null

  if (Number.isInteger(id)) {
    for (let data in menuDataLink) {
      for (let { node } of menuDataLink[data].edges) {
        if (node.internalId === id) {
          path = node.path.alias
          break
        }
      }
    }
  } else {
    path = `/${searchValue}`
  }

  return (
    <Link to={path} {...props}>
      {children}
    </Link>
  )
}

export default EntityLink
